export default {
  application: { 
    title: "Sans Caffeine",
    description: "Solutions without Coffee"
  },
  cdn: {
    URL: "https://sans-caffeine.com"
  },
  api: {
    URL: "https://api.sans-caffeine.com/v1"
  },
  authentication: {
    AUTH_CLIENT: "1mlcm2s5rbsbm8s6pnl95j31i2"
  }
}